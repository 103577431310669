/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Nunc's base styles.
/* ----------------------------------------------------------------------------------------------------- */

body.light .mat-flat-button.mat-primary {
    background-color: rgb(217, 177, 116);
}

.header-bar {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#323232+0,6d6e6d+100 */
    background: #323232; /* Old browsers */
    background: -moz-linear-gradient(
            left,
            #323232 0%,
            #6d6e6d 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
            left,
            #323232 0%,
            #6d6e6d 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
            to right,
            #323232 0%,
            #6d6e6d 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#323232', endColorstr='#6d6e6d', GradientType=1); /* IE6-9 */
}

.bg-light .mat-icon {
    color: #d9b174;
}

.header-bar {
    .nunc-horizontal-navigation-item-active {
        @apply rounded-md;
        background: #505050;
        min-height: 42px;
    }
}

/*===== 23/2/2022 =====*/
.prod-detail-table .grid.mt-8,
.prod-detail-table .grid.mt-8 .mx-6 {
    margin: 0 !important;
}

.prod-detail-table table.mat-table thead {
    background-color: #f8f8fa;
}

.prod-detail-table table.mat-table tbody tr {
    height: 3rem !important;
}

.prod-detail-table table.mat-table thead tr th {
    text-transform: uppercase;
    font-size: 11px;
}

.prod-detail-table table.mat-table tbody tr td {
    font-size: 11px;
}

.prod-detail-table table.mat-table tbody tr td.cdk-column-feedback svg {
    width: 13px !important;
    height: 13px !important;
}

.prod-detail-table table.mat-table tbody tr td mat-icon svg {
    height: 13px !important;
    width: 13px !important;
}

.prod-detail-table mat-paginator .mat-paginator-page-size,
.prod-detail-table .mat-paginator .mat-paginator-container .mat-paginator-range-actions,
.prod-detail-table mat-paginator .mat-paginator-page-size,
.prod-detail-table .mat-paginator .mat-paginator-container .mat-paginator-range-actions > button {
    color: #fff;
}

.prod-detail-table mat-paginator .mat-paginator-page-size,
.prod-detail-table .mat-paginator .mat-paginator-container .mat-paginator-range-actions > button svg {
    fill: #fff;
}

.prod-detail-table mat-paginator .mat-paginator-page-size,
.prod-detail-table mat-paginator .mat-paginator-range-actions {
    margin: 0 !important;
}

a.edit {
    display: inline-block;
    color: #9d9075;
    text-decoration: underline;
}

.cdk-overlay-dark-backdrop {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.mat-card-header-text {
    display: none !important;
}

.rcp-box mat-card-header .mat-card-header-text {
    display: initial !important;
    margin: 0;
}

.rcp-box mat-card-header .mat-card-header-text mat-icon {
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    font-size: 20px;
}

// .rcp-box mat-form-field {
//     display: block;
// }

// .rcp-box mat-form-field input[type="text"] {
//     width: 100%;
//     background-color: #fff;
//     border-radius: 4px;
//     padding: 5px 10px;
// }

.pointer {
    cursor: pointer !important;
}
