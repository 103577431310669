lib-query-table {
    @apply -mt-4 -mb-4 shadow border-b border-gray-200 sm:rounded-lg;

    .mat-paginator {
        @apply rounded-b-lg text-sm;
    }

    mat-table {
        box-shadow: none;

        mat-header-cell {

            &:first-child {

                width: auto !important;
            }
        }

        mat-cell,
        mat-header-cell {
            flex: 1 1 100px;
            @apply px-2;
        }

        mat-row {
            min-height: 64px;
            @apply transition-all;
            &.mat-row-active {
                @apply bg-primary;
                mat-cell {
                    @apply text-white;
                    span {
                        @apply text-white;
                    }

                    mat-icon {
                        @apply text-white;
                    }
                }
            }
            &.mat-row-highlight {
                .mat-cell {
                    @apply font-bold;
                }
            }
        }
    }
}
